import React from "react";
import Navbar from "../../../../Home/components/Navbar";
import TPSSvg from "../../../../utils-component/tpsSvg";
import hero from "../../../../../assets/images/about-hero.jpg"

export default function BackupHero() {
  return (
    // HERO WRAPPER
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      {/* CONTENT WRAPPER */}
      <div className="bg-black py-14 lg:py-10 bg-opacity-70 max-h-screen overflow-hidden ">
        <div className="flex md:flex-row">
          {/* lrft text */}
          <div className="text-left px-6 md:p-16 md:py-8">
            <text className="text-left text-blue-400">Services</text>
            <div className="text-left my-10  font-bold text-3xl md:text-5xl text-white">
              Backup & Recovery
            </div>
            <text className="text-white text-left md:text-2xl">
              Ensure your business is always available with fast reliable
              backups.
            </text>
          </div>

          {/* Svg */}
          <div className="hidden relative md:block md:w-1/2 md:2/3 lg:1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}
