import React from "react";
import Navbar from "../../Home/components/Navbar";
import TPSSvg from "../../utils-component/tpsSvg";

export default function TermsHero() {
  return (
    // HERO WRAPPER
    <section id="terms-hero" className="bg-blue-950 bg-opacity-70">
      <Navbar />
      <div className="bg-black py-8 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left px-6 md:p-12 md:pt-16">
            <h1 className="text-white text-4xl md:text-[3.25rem] font-semibold md:font-bold text-left my-6 md:my-8 md:leading-tight">
              Terms & Conditions
            </h1>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:w-1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}
