import React from "react";
import Navbar from "../../Home/components/Navbar";
import TPSSvg from "../../utils-component/tpsSvg";
import hero from "../../../assets/images/support-hero.jpg"

const SupportHero = () => {
  return (
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      <div className="bg-black py-28 lg:py-20 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left px-6 md:p-12 md:pt-8 lg:p-14">
            <p className="text-primary100 font-semibold text-lg">Support</p>
            <h1 className="text-white text-4xl md:text-5xl font-semibold md:font-bold text-left my-6 md:my-8 md:leading-[3.5rem]">
              We're Here to Help
            </h1>
            <p className="text-white text-semibold text-left mb-10 text-xl">
              Are you speaking with one of our support engineers?
            </p>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:w-1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SupportHero;
