import React from 'react'
import { Link } from 'react-router-dom'
import Checkbox from '../../../../utils-component/Checkbox';

type Props = {}

const GetInTouch = (props: Props) => {
  return (
    <section id="get-in-touch pt-16 pb-16">
      <div className="container">
        <h2 className="title">Get in touch</h2>
        <div className="max-w-full my-4">
          <form action="">
            <div className="flex flex-col lg:flex-row">
              <div className="w-full">
                {formInput.map((item, index) => {
                  const { label, type } = item;
                  return (
                    <div className="flex flex-col flex-wrap xs:flex-row items-start gap-2 mb-6 w-full">
                      <label
                        htmlFor=""
                        className="inline-block w-full md:w-[30%] text-base font-normal text-primary-dark"
                      >
                        {label}
                        <span className="text-primary100 ml-2">*</span>
                      </label>
                      <div className="w-full md:w-2/3">
                        <input
                          type={type}
                          className="bg-light border-2 border-input rounded-full outline-0 px-7 h-12 w-full"
                          id=""
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="w-full lg:ml-10">
                <div className="flex flex-col mb-4">
                  <label htmlFor="" className="inline-block w-full">
                    Message
                    <span className="text-primary100 ml-2">*</span>
                  </label>
                  <div className="grow mt-3">
                    <textarea className="w-full p-7 bg-light outline-0 border-2 border-input rounded h-40"></textarea>
                  </div>
                </div>
                <div className="flex w-full my-6">
                  <Checkbox />
                  <label htmlFor="" className="ml-2">
                    I agree to the{" "}
                    <Link to="/terms" className="text-primary100 font-medium">
                      Data Terms & Conditions*
                    </Link>
                  </label>
                </div>
                <div className="mt-4 flex justify-end">
                  <input
                    type="submit"
                    value="Submit"
                    className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm sm:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default GetInTouch

const formInput = [
  {
    label: 'First Name',
    type: 'text'
  },
  {
    label: 'Last Name',
    type: 'text'
  },
  {
    label: 'Email Address',
    type: 'email'
  },
  {
    label: 'Phone Number',
    type: 'number'
  },
  {
    label: 'Company',
    type: 'text'
  }
]