import React from "react";
// import MySvg from "../../assets/svg/svg-clock-sample.svg";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/programmer_tools.json";
export default function tpsSvg() {
  const defaultOptions = {
    autoplay: true,
    loop: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div>
      <Lottie options={defaultOptions} />
    </div>
  );
}
