import React from "react";
import TPSSvg from "../../../../utils-component/tpsSvg";
import Navbar from "../../../../Home/components/Navbar";
import hero from "../../../../../assets/images/cyber-hero.jpg"

type Props = {};

const CyberHero = (props: Props) => {
  return (
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      <div className="bg-black py-14 lg:py-10 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left px-6 md:p-12 md:pt-4 lg:px-14 lg:py-6">
            <p className="text-primary100 font-semibold text-lg">Services</p>
            <h1 className="text-white text-4xl md:text-5xl lg:text-5xl font-bold text-left my-6 md:my-8 md:leading-[1.75]">
              Cyber Security Services
            </h1>
            <p className="text-white text-semibold text-left mb-10 text-xl">
              Security solutions against potential threats.
            </p>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:w-1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CyberHero;
