import React from "react";
import Navbar from "../../Home/components/Navbar";
import TPSSvg from "../../utils-component/tpsSvg";
import lightbulb from '../../../assets/svg/light-bulb.svg';
import web from "../../../assets/svg/web.svg"
import user from "../../../assets/svg/user.svg"
import upload from "../../../assets/svg/upload.svg"
import expert from "../../../assets/svg/expert.svg"
import hero from "../../../assets/images/about-hero.jpg"

export default function AboutHero() {
  return (
    // HERO WRAPPER
    <section id="hero" className="bg-black bg-opacity-70" style={{backgroundImage: `url(${hero})`}}>
      <Navbar />
      {/* CONTENT WRAPPER */}
      <div className="bg-black py-12 md:py-10 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left px-6 py-10 md:px-16 md:py-8">
            <p className="text-blue-400">About</p>
            <h2 className="my-4 md:my-8 font-bold text-3xl md:text-5xl text-white">
              Who Are TPS?
            </h2>
            <p className="text-white text-left md:text-xl">
              The Point Services Solution Providers is a leading Managed IT
              based in Lagos.
            </p>
          </div>

          {/* Svg */}
          <div className="hidden relative md:block md:w-1/2 md:2/3 lg:1/2">
            <TPSSvg />
          </div>
        </div>
      </div>

      {/* about hero features */}
      <div className="grid grid-cols-2 text-xs py-6 px-6 md:px-14 items-center opacity-70 md:text-lg md:flex flex-row border-t-2 bg-gray-300 border-gray-400 md:space-y-0 md:space-x-20 md:justify-evenly">
        {/* item 1 */}
        <div className="flex-row items-center my-3 hidden md:flex md:my-0 space-x-2 md:space-x-5 text-blsck">
          <img
            alt="loading...."
            src={lightbulb}
          />
          <p>Est. 2012</p>
        </div>

        {/* item 2 */}
        <div className="flex flex-row items-center my-3 md:my-0 space-x-2 md:space-x-5 text-black ">
          <img
            alt='web'
            src={web}
          />{" "}
          <p>Worldwide Support</p>
        </div>

        {/* item 3 */}
        <div className="flex flex-row items-center my-3 md:my-0 space-x-2 md:space-x-5 text-black">
          <img
            alt="users"
            src={user}
          />{" "}
          <p>Team of Experts</p>
        </div>

        {/* item 4 */}
        <div className="flex flex-row items-center my-3 md:my-0 space-x-2 md:space-x-5 text-black">
          <img
            alt="upload"
            src={upload}
          />{" "}
          <p>Cloud Services</p>
        </div>
        {/* item 5 */}
        <div className="flex flex-row items-center my-3 md:my-0 space-x-2  md:space-x-5 text-black">
          <img
            alt="loading...."
            src={expert}
          />{" "}
          <p>Expert Solutions</p>
        </div>
      </div>
    </section>
  );
}
