import React from "react";
import Navbar from "../../../../Home/components/Navbar";
import TPSSvg from "../../../../utils-component/tpsSvg";
import hero from "../../../../../assets/images/communications-hero.jpg"

export default function CommunicationHero() {
  return (
    // HERO WRAPPER
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      {/* CONTENT WRAPPER */}
      <div className="bg-black py-14 lg:py-10 bg-opacity-70 max-h-screen overflow-hidden ">
        <div className="flex md:flex-row">
          {/* left text */}
          <div className="text-left w-full md:w-1/2 px-6 md:p-16 md:py-6">
            <text className="text-left text-blue-400">Services</text>
            <div className="text-left my-8 leading-8 font-bold text-3xl md:text-6xl text-white">
              Business Communication Systems
            </div>
            <text className="text-white text-left md:text-xl">
              Bespoke business communication systems.
            </text>
          </div>

          {/* Svg */}
          <div className="hidden relative md:block md:w-1/2 md:2/3 lg:1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}
