import React from 'react'
import { PiCaretRight } from 'react-icons/pi';
import { Link } from 'react-router-dom';


const SupportFaqs = () => {
  return (
    <section id="support-bg" className="py-11 sm:py-20">
      <div className="max-w-screen-xl px-8 mx-auto">
        <div className="block max-w-3xl">
          <p className="mb-4">FAQs</p>
          <h2 className="font-bold text-3xl sm:text-4xl text-primary-dark md:leading-snug">
            You can also browse the topics below to find what you are looking
            for.
          </h2>
        </div>
        <div className="pt-12 lg:pt-16 xl:pt-20">
          <div className="relative flex flex-wrap mb-10 lg:mb-14 -mx-4 lg:-mx-8">
            <div className="w-full md:w-1/3 px-4 lg:px-8 relative">
              <h3 className="text-primary-dark leading-tight text-xl md:text-2xl font-bold mb-7">
                FAQs
              </h3>
            </div>
            <div className="w-full md:w-1/3 px-4 lg:px-8 relative">
              <div className="flex flex-wrap -mx-4 lg:-mx-8">
                <div className="flex w-full items-center gap-1 mb-4 px-4 lg:px-8 group">
                  <PiCaretRight className="text-primary100 text-lg mt-[1px]" />
                  <Link
                    to="/faqs"
                    className="group-hover:text-primary100 font-normal"
                  >
                    Are TPS Specialists?
                  </Link>
                </div>
                <div className="flex w-full items-center gap-1 mb-4 px-4 lg:px-8 group">
                  <PiCaretRight className="text-primary100 text-lg mt-[1px]" />
                  <Link
                    to="/faqs"
                    className="group-hover:text-primary100 font-normal"
                  >
                    How do the fees work?
                  </Link>
                </div>
                <div className="flex w-full gap-1 mb-4 px-4 lg:px-8 group">
                  <PiCaretRight className="text-primary100 text-lg mt-[1px]" />
                  <Link
                    to="/faqs"
                    className="group-hover:text-primary100 font-normal"
                  >
                    Do you support all systems - Windows, MACs, Android etc?
                  </Link>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 lg:px-8 relative">
              <div className="flex flex-wrap -mx-4 lg:-mx-8">
                <div className="flex w-full items-center gap-1 mb-4 px-4 lg:px-8 group">
                  <PiCaretRight className="text-primary100 text-lg mt-[1px]" />
                  <Link
                    to="/faqs"
                    className="group-hover:text-primary100 font-normal"
                  >
                    What size of business do you support?
                  </Link>
                </div>
                <div className="flex w-full items-center gap-1 mb-4 px-4 lg:px-8 group">
                  <PiCaretRight className="text-primary100 text-lg mt-[1px]" />
                  <Link
                    to="/faqs"
                    className="group-hover:text-primary100 font-normal"
                  >
                    Does TPS deal with hardware & software?
                  </Link>
                </div>
                <div className="flex w-full gap-1 mb-4 px-4 lg:px-8 group">
                  <PiCaretRight className="text-primary100 text-lg mt-[1px]" />
                  <Link
                    to="/faqs"
                    className="group-hover:text-primary100 font-normal"
                  >
                    Will you treat me like an idiot?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SupportFaqs