import React from "react";
import backup from "../../../../../assets/images/backup.jpg"
import branding from "../../../../../assets/images/branding.jpg"
import recovery from "../../../../../assets/images/recovery.jpg"

export default function BackupBlog() {

    const reatedBlog = [
        {
          date:"11/06/2019",
          image: backup,
          description: "Back Up Your Data: 5 Tips for Future-Proofing Your Infastructure"
        },
        {
          date:"31/01/2019",
          image: branding,
          description: "Building Your Business: How Private cloud can help your business Grow"
        },
        {
          date:"02/02/2017",
          image: recovery,
          description: "Can your business Survive? "
        },
    ]

  return (
    <section id="related-blog" className="container py-4 md:py-10">
      <h4 className="text-primary-dark font-bold text-[2rem] lg:text-[2.65rem] mb-4">
        Recent Blogs
      </h4>

      <div className="flex mx-auto flex-col md:grid md:grid-cols-3 md:gap-4 space-y-8 md:space-x-5 md:space-y-0">
        {/* content */}

        {reatedBlog.map((blog, index) => (
          <div key={index} className="space-y-4 cursor-pointer">
            <img
              alt=""
              src={blog.image}
              className="h-72 w-full hover:scale-105 hover:border-b-4 hover:border-blue-400  duration-500"
            />
            <div className="">
              <p className="text-blue-400">{blog.date}</p>
              <p className="text-xl my-5 font-bold text-black">
                {blog.description}
              </p>
              <p className="border-b-2 hover:border-b-0 duration-200 border-blue-400">
                Learn more
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

