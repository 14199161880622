import React from 'react'
import { FaCheck } from 'react-icons/fa';

type Props = {}

const Checkbox = (props: Props) => {
  return (
    <label className="custom-checkbox">
      <input type="checkbox" />
      <div className="circle">
        <FaCheck className="checkmark" />
      </div>
    </label>
  );
}

export default Checkbox