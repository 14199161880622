import React from "react";
import { Link } from "react-router-dom";

export default function IssuesFaqs() {
  return (
    <section id="issues-faqs">
      <div className="flex flex-col space-y-8 px-6 md:px-10 py-5 md:py-10 bg-white md:flex-row">
        {/* top info */}
        <div className="flex flex-col space-y-5">
          <p className="text-xl">FAQS</p>
          <h1 className="font-bold text-black text-3xl md:text-5xl">
            Frequently asked Questions
          </h1>

          <Link to='/faqs'>
          <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm md:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
            View all FAQS
          </button>
          </Link>
        </div>

        {/* quetsions */}
        <div className="grid md:w-3/4 px-6 md:px-0 md:grid-cols-2 gap-4 md:gap-10">
          <div className="flex flex-row items-center">
            <ul>
              <li className="text-black list-disc">
                How often should I run CYDEFENCE vulnerability scans?
              </li>
            </ul>
          </div>

          <div className="flex flex-row items-center">
            <ul>
              <li className="text-black list-disc">
                How often should I run CYDEFENCE vulnerability scans?
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
