import React from "react";
import Navbar from "../../../../Home/components/Navbar";
import TPSSvg from "../../../../utils-component/tpsSvg";
import hero from "../../../../../assets/images/it-and-roi-hero.jpg"

export default function IssuesHero() {
  return (
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      <div className="bg-black py-14 md:py-12 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left w-full md:w-1/2 px-6 md:p-10 md:pt-8 lg:py-8">
            <p className="text-primary100 font-semibold text-lg">Challenge</p>
            <h1 className="text-white text-4xl md:text-5xl font-semibold md:font-bold text-left my-6 md:my-8 md:leading-tight">
              Small Issues which Create Big Problems
            </h1>
            <p className="text-white text-medium text-left mb-10 text-xl">
              Don’t let small IT issues become business-critical issues.
            </p>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:w-1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}
