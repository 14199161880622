import React from "react";
import { Link } from "react-router-dom";

export default function TopMostInfo() {
  return (
    <section id="top-Info" className="bg-sky-800 text-center p-1 md:p-4 text-white">
      <p>
        Can your business securely manage multiple devices in one place?{" "}
        <Link
          to="/services/cyber-security/mobile-security"
          className="text-blue-400 font-bold md:border-b-2 border-blue-400 hover:border-b-0 transition-all duration-200"
        >
          Learn more
        </Link>{" "}
      </p>
    </section>
  );
}
