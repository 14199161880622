import React from 'react'
import TPSSvg from "../../../../utils-component/tpsSvg";
import Navbar from '../../../../Home/components/Navbar'
import hero from "../../../../../assets/images/challenges_it-security.jpg"

type Props = {}

const SecurityHero = (props: Props) => {
  return (
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      <div className="bg-black py-14 md:py-10 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left w-full md:w-1/2 px-6 md:p-10 md:pt-8 lg:py-8">
            <p className="text-primary100 font-semibold text-lg">Challenge</p>
            <h1 className="text-white text-4xl md:text-5xl font-semibold md:font-bold text-left my-6 md:my-8 md:leading-tight">
              Does IT Security Matter? The Importance of IT Security
            </h1>
            <p className="text-white text-semibold text-left mb-10 text-xl">
              No matter how new your hardware is, your data is only as secure as
              the IT security procedures you have in place. This means your data
              is always at risk, so be one step ahead and ensure your data is as
              secure as it can possibly be.
            </p>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:w-1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecurityHero