import React from 'react'
import security from "../../../../../assets/images/security.jpg"
import { Link } from 'react-router-dom';

type Props = {}

const WhyTPS = (props: Props) => {
  return (
    <div className="pb-8 md:pb-16">
      <div className="max-w-screen-xl mx-auto px-7">
        <div className="relative flex flex-col md:flex-row items-center md:items-stretch">
          <div className="w-full h-full md:w-1/2 mt-12 mb-8 md:mb-0 md:mt-0 md:pr-7 lg:pr-7">
            <img src={security} alt="security" className="w-full" />
          </div>
          <div className="w-full md:w-1/2 md:flex md:flex-col md:justify-center md:pl-10 lg:pl-20">
            <h3 className="text-primary-dark text-3xl md:text-4xl font-bold mb-7">
              Why TPS?
            </h3>
            <h6 className="mb-3 md:mb-6 text-xl md:text-2xl">
              We have over 30 years of supplying IT and electronic systems,
              right from the nuts and bolts to sophisticated servers, networks,
              software and IT infrastructure.
            </h6>
            <p className="mb-6 text-[1rem] lg:text-lg">
              TPS understands how to dig into what is possible and ensure that
              the best possible cover is made with what is already available,
              and recommend upgrades if there are areas of weakness. We also
              understand business, management and the need for modern approaches
              to reduce risk, potential fines, costs and reputational damage
              from not securing the electronic business assets of a company.
            </p>
            <Link
              to="/about"
            >
              <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm md:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
                About Us
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyTPS