import React from "react";
import panel from "../../../assets/images/panel.jpg"
import database from "../../../assets/svg/backup.svg"
import cloud from "../../../assets/svg/upload.svg"
import text from "../../../assets/svg/text.svg"
import shield from "../../../assets/svg/shield.svg"
import lifering from "../../../assets/svg/expert.svg"

export default function SpecialiseSupport() {
  return (
    <section
      id="Specialise"
      className="py-8 md:py-14 bg-sky-50 bg-gradient-to-t"
    >
      <div className="container">
        {/*section decription */}
        <div className="text-left text-black">
          <p className="mb-5 text-left text-lg">A wide Range Of IT Services</p>
          <h3 className="text-3xl font-bold text-left mb-10 md:text-4xl">
            We Specialise in IT Support & Cyber Security.
          </h3>
        </div>

        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {/* item 1 */}
          <div className="flex flex-col space-y-3 text-left md:my-5 px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
            <img
              alt="database"
              className="h-10 w-10 md:h-10 md:w-10"
              src={database}
            />
            <p className="text-xl md:text-2xl font-semibold">
              Backup & Recovery
            </p>

            <p className="text-left text-lg">
              Leading secure back-up and recovery services for businesses.
            </p>

            <div className="text-left group">
              <p className="text-lg">Learn more</p>
              <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>{" "}
            </div>
          </div>

          {/* item 2 */}
          <div className="flex flex-col md:my-5  space-y-3 text-left  px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
            <img
              alt="cloud"
              className="h-10 w-10 md:h-10 md:w-10"
              src={cloud}
            />
            <p className="text-xl md:text-2xl font-semibold">Cloud Solutions</p>

            <p className="text-left text-lg">
              A wide range of cloud solutions for businesses.
            </p>

            <div className="text-left group">
              <p className="text-lg">Learn more</p>
              <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>{" "}
            </div>
          </div>

          {/* item 3 */}
          <div className="flex flex-col md:my-5 space-y-3 text-left  px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
            <img alt="chat" className="h-10 w-10 md:h-10 md:w-10" src={text} />
            <p className="text-xl md:text-2xl font-semibold">Communications</p>

            <p className="text-left tex-lg">
              Bespoke business cummunication systems.
            </p>

            <div className="text-left group">
              <p className="text-lg">Learn more</p>
              <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>{" "}
            </div>
          </div>

          {/* item 4 */}
          <div className="flex flex-col md:my-5  space-y-3 text-left  px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
            <img
              alt="shield"
              className="h-10 w-10 md:h-10 md:w-10"
              src={shield}
            />{" "}
            <p className="text-xl md:text-2xl font-semibold">
              Cyber Security Services
            </p>
            <p className="text-left tex-lg">
              Trust your IT needs with experts in delivering IT Support
              excellence.
            </p>
            <div className="text-left group">
              <p className="text-lg">Learn more</p>
              <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>{" "}
            </div>
          </div>

          {/* item 5 */}
          <div className="flex flex-col md:my-5  space-y-3 text-left  px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
            <img
              alt="lifering"
              className="h-10 w-10 md:h-10 md:w-10"
              src={lifering}
            />
            <p className="text-xl md:text-2xl font-semibold">
              IT Support and consultancy services
            </p>

            <p className="text-left tex-lg">
              Expert IT support when you need it most.
            </p>

            <div className="text-left group">
              <p className="text-lg">Learn more</p>
              <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>
            </div>
          </div>
        </div>

        {/* why choose TPS */}
        <div className="flex flex-col items-center space-y-5 my-10 mx-auto md:space-y-0 md:space-x-16 md:flex-row">
          <img
            alt="panel"
            src={panel}
            className="w-full h-auto md:w-1/2 md:mb-0 object-center object-cover md:aspect-ratio-square md:h-full"
          />

          <div className="flex flex-col space-y-4 text-left">
            <div className="text-gray-500 text-lg">Why Choose</div>
            <h3 className="text-2xl font-bold text-left md:text-4xl">
              A Forward-Thinking Managed IT Services Provider
            </h3>
            <p className="text-gray-500 text-left text-lg">
              TPS’s diverse portfolio of IT solutions means that we can
              confidently provide a positive and measurable impact on your
              businesses operations.
            </p>
            <p className="text-gray-500 text-left text-lg">
              Our approach is simple; we listen to your challenges and then
              design and implement a solution that’s exactly right for your
              business.
            </p>

            <div className="text-left group">
              <p className="text-lg">Meet the team</p>
              <div className="border-b-2 w-28 border-sky-400 group-hover:border-b-0 duration-200"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
