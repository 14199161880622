import React from 'react'
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

type Props = {}

const FAQs = (props: Props) => {
  return (
    <div className="pb-12 md:pb-20">
      <div className="container">
        <h2 className="text-primary-dark text-2xl xs:text-3xl md:text-4xl leading-tight mb-7 font-bold">
          Mobile Security - Frequently Asked Questions
        </h2>
        <ul className='my-4'>
          <li className="flex items-start pb-6">
            <span className="pt-[0.3rem] pr-2 text-xl text-primary100">
              <IoIosArrowForward />
            </span>
            <p className="text-primary-dark font-semibold leading-tight text-lg md:text-xl">
              Do you support all systems – Windows, Macs, Androids etc?
            </p>
          </li>
          <li className="flex items-start pb-6">
            <span className="pt-[0.3rem] pr-2 text-xl text-primary100">
              <IoIosArrowForward />
            </span>
            <p className="text-primary-dark font-semibold leading-tight text-lg md:text-xl">
              How do the fees work?
            </p>
          </li>
        </ul>
        <Link
          to="/faqs"
        >
          <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm md:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
            View all FAQs
          </button>
        </Link>
      </div>
    </div>
  );
}

export default FAQs