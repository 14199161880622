import React from 'react'
import accountancy from "../../../../../assets/images/accountancy.jpg"
import formula from "../../../../../assets/images/formula.jpg"
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';

type Props = {}

const stories = [
  {
    name: "Force India F1",
    title: "F1 fata finds a new home with TPS",
    image: formula,
  },
  {
    name: "Bronsens",
    title:
      "Oxfordshire accountants rely on the cloud for protection of client financial data",
    image: accountancy,
  },
];

const MobileStories = (props: Props) => {
  return (
    <section className="py-11 md:py-14" id="linear-bg">
      <div className="max-w-screen-xl mx-auto px-7">
        <h5 className="text-md mb-4">Success Stories</h5>
        <h3 className="text-primary-dark text-3xl md:text-4xl font-bold mb-8">
          Related Success Story
        </h3>
        <div className="mt-10 mb-10 flex flex-col space-y-10">
          {stories.map((item, index) => {
            const { image, name, title } = item;
            return (
              <div
                key={index}
                className="item group h-72 item md:h-80 lg:h-96 cursor-pointer"
              >
                <img
                  src={image}
                  alt={name}
                  className="w-full h-full rounded-sm group-hover:scale-125 group-hover:duration-[4s]"
                />

                <div className="item-gradient"></div>

                <div className="absolute w-11/12 top-6 left-6 md:top-10 md:left-14 md:w-3/5 z-20">
                  <h5 className="text-primary100 text-lg font-medium">
                    {name}
                  </h5>
                  <h3 className="text-2xl md:text-3xl lg:text-[2rem] font-semibold text-white my-8 lg:leading-normal capitalize">
                    {title}
                  </h3>
                  <button className="p-2 md:p-3 rounded-full border-2 border-primary100 text-white">
                    <IoIosArrowForward size={24} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <Link
          to="/success-stories"
        >
          <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm md:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
            More success stories
          </button>
        </Link>
      </div>
    </section>
  );
}

export default MobileStories