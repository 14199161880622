import React from "react";
import cookie from "../../../assets/images/cookie.png";
import { useCookies } from "react-cookie";

interface CookiesConcentProps {
  onclick: () => void;
  showCookies: boolean;
}

const Cookiesconcent: React.FC<CookiesConcentProps> = ({
  onclick,
  showCookies,
}) => {
  const [cookies, setCookie] = useCookies(["cookiesConcent"]);

  const handle = () => {
    let d = new Date();
    d.setTime(d.getTime() + 60 * 60 * 1000);
    setCookie("cookiesConcent", true, { path: "/", expires: d });
    onclick();
  };

  return !cookies.cookiesConcent && showCookies ? (
    <section
      id="cookies"
      className="left-0 top-1/3 right-0 fixed "
      style={{ zIndex: "9999" }}
    >
      <div className="max-w-7xl mx-auto p-6 space-y-6 lg:mt-56">
        <div className="flex flex-col h-70 lg: xs: w-9/12  mx-auto  lg:w-11/12 p-5 lg:p-10   space-y-4 justify-center items-center bg-white rounded-sm shadow-lg">
          <div className="flex flex-row mr-auto">
            <div className="flex flex-row mr-auto">
              <img src={cookie} alt="cookie" className="w-11 mr-4" />
              <h1 className="font-bold text-3xl">Cookies</h1>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-y-0 md:space-x-3">
            <div className="flex flex-col md:flex-row">
              <p>
                This site uses cookies to help improve user experience. For
                these reasons, we may share data with our analytics partners. By
                using TPS, you accept our use of cookies.{" "}
                <a
                  href="/"
                  className="underline text-blue-500 text-lg font-semi-bold"
                >
                  Find out more
                </a>{" "}
              </p>
              <button
                onClick={handle}
                className="cursor-pointer mx-auto py-2 px-10 text-black text-lg bg-white rounded-full outline outline-blue-400 h-12 ml-2 mt-5 md:mt-0 hover:bg-sky-400 duration-200"
              >
                Agree
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default Cookiesconcent;
