import React from 'react'
import { AiOutlineRight } from 'react-icons/ai';

const KeepUpToDate = () => {
  return (
    <section>
      <div className="container flex flex-col sm:flex-row mx-auto justify-between items-center max-w-6xl h-60 sm:h-48 shadow-xl p-6 space-y-6">
        <div className="flex flex-col">
          <h2 className="text-2xl font-bold">Keep up to date</h2>
          <p className="sm:mt-2 mt-4">
            Join our mailing list and stay up to date with all the latest in the
            IT world
          </p>
        </div>
        <div className="flex gap-4 md:gap-2">
          <input
            type="text"
            placeholder="Email Address"
            className="flex-1 px-4 placeholder:text-black placeholder:text-sm rounded-full bg-gray-100 text-black hover:border-primary100 outline-primary100"
          />
          <div className="rounded-full p-4 border-2 cursor-pointer border-primary100">
            <AiOutlineRight />
          </div>
        </div>
      </div>
    </section>
  );
}

export default KeepUpToDate
