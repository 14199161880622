import React from "react";
import Navbar from "../../../../Home/components/Navbar";
import TPSSvg from "../../../../utils-component/tpsSvg";
import hero from "../../../../../assets/images/cloud-hero.jpg"

export default function CloudHero() {
  return (
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      {/* CONTENT WRAPPER */}
      <div className="bg-black py-14 md:py-10 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          {/* lrft text */}
          <div className="text-left px-6 md:p-16 md:py-10">
            <text className="text-left text-blue-400">Services</text>
            <h1 className="text-left my-5 font-bold text-3xl md:text-5xl text-white md:leading-tight">
              Cloud managed services
            </h1>
            <text className="text-white text-left md:text-xl">
              Cloud solutions for scalable growth.
            </text>
          </div>

          {/* Svg */}
          <div className="hidden relative md:block md:w-1/2 md:2/3 lg:1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}
