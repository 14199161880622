import React from 'react'
import stats from "../../../../../assets/images/stats.jpg"

export default function CommunicationDescription() {
  return (
    <section>
      <div className="min-h-screen">
        {/* top description */}
        <div className="flex flex-col pt-8 pb-2 px-6 md:flex-row md:space-x-16 md:px-10 xl:px-16">
          {/* image section */}
          <div className="w-full mx-auto md:-mt-0 ">
            <img
              alt="stats"
              src={stats}
              className="w-full shadow-lg rounded h-full md:mb-0 object-center object-cover md:inset-0 "
            />
          </div>

          {/* right text */}
          <div className="flex-col mt-10 md:mt-0 pb-0 space-y-10 md:py-10 w-full">
            <div className="text-3xl md:text-4xl font-bold text-black mx-auto  md:text-left">
              Secure, reliable communications from TPS
            </div>

            {/* text */}
            <div className="text-left text-gray-600 text-xl space-y-5 md:space-y-10">
              <p className="leading-8 font-semibold text-black text-xl">
                Email, Internet connectivity and phones are the lifeblood of an
                organisations’ ability to communicate with customers and
                suppliers, ensure yours are reliable!
              </p>

              <p className="leading-8 font-medium text-gray-500 ">
                TPS can ensure you and your organisation are always available to
                communicate through whichever means you need. TPS networking
                specialists can advise you through the myriad of Internet
                connectivity options available and ensure they are specified
                appropriately for you.
              </p>
              <p className="leading-8 font-medium text-gray-500 ">
                TPS will ensure you have the right communication solutions for
                your organisation to keep you and your staff connected be that
                hosted telephony systems, Microsoft 365 hosted email solutions
                or redundant Internet connectivity.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* services */}
      <div className="grid p-10 bg-white cursor-pointer gap-4 md:grid-cols-2 lg:grid-cols-3">
        {/* item 1 */}
        <div className="flex flex-col space-y-3 text-left my-3 md:my-5 px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
          <p className="text-lg text-blue-400 font-medium">Communications </p>
          <p className="text-xl font-semibold">Broadband</p>

          <p className="text-left text-lg leading-10 text-gray-500">
            Broadband that works for your Business.
          </p>

          <div className="text-left group">
            <p className="text-lg">
              Learn more
            </p>
            <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>{" "}
          </div>
        </div>

        {/* item 2 */}
        <div className="flex flex-col md:my-5  space-y-3 text-left  px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
          <p className="text-lg text-blue-400 font-medium">Communications </p>
          <p className="text-xl font-semibold">VolP Solutions - EVE VolP</p>

          <p className="text-left text-lg leading-10 text-gray-500">
            A cloud-based VolP communications system keeping your business
            connected.
          </p>

          <div className="text-left group">
            <p className="text-lg">
              Learn more
            </p>
            <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>{" "}
          </div>
        </div>

        {/* item 3 */}
        <div className="flex flex-col md:my-5  space-y-3 text-left  px-7 py-10 border-2 border-sky-100 duration-200 transition-colors hover:border-sky-400 rounded-lg">
          <p className="text-lg text-blue-400 font-medium">Communications </p>
          <p className="text-xl font-semibold">Mobile Communications</p>

          <p className="text-left text-lg leading-10 text-gray-500">
            Tailor communications to your business needs
          </p>

          <div className="text-left group">
            <p className="text-lg">
              Learn more
            </p>
            <div className="border-b-2 w-24 border-sky-400 group-hover:border-b-0 duration-200"></div>{" "}
          </div>
        </div>
      </div>
    </section>
  );
}
