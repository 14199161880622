import React from 'react'
import MobileHero from './components/MobileHero';
import './mobile-security.css'
import SecurityCoverage from './components/SecurityCoverage';
import MobileControl from './components/MobileControl';
import MobileStories from './components/MobileStories';
import MobileBlog from './components/MobileBlog';
import Benefits from './components/Benefits';
import WhyTPS from './components/WhyTPS';
import FAQs from './components/FAQs';
import MobileServices from './components/MobileServices';

type Props = {}

const index = (props: Props) => {
  return (
    <>
      <MobileHero />
      <SecurityCoverage />
      <MobileControl />
      <Benefits />
      <WhyTPS />
      <FAQs />
      <MobileStories />
      <MobileBlog />
      <MobileServices />
    </>
  )
}

export default index