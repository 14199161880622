import React from 'react'
import phone from "../../../../../assets/images/Phon_call.jpg"
import { Link } from 'react-router-dom';

type Props = {}

const SecurityCoverage = (props: Props) => {
  return (
    <div className="relative pt-0 md:py-14">
      <div className="container">
        <div className="relative flex flex-col md:flex-row items-center md:items-stretch">
          <div className="w-full md:w-1/2 mt-0 md:mt-0 md:pl-5 md:order-2">
            <div className="w-full h-full md:aspect-ratio-square relative">
              <img
                src={phone}
                alt=""
                className="w-full h-auto rounded shadow-lg md:h-full md:absolute md:inset-0 object-cover"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-7 md:mt-0 md:flex md:flex-col md:justify-center md:pr-10 lg:pr-20 order-1">
            <h3 className="text-primary-dark text-2xl md:text-4xl leading-tight font-bold mb-4 md:mb-7">
              Security coverage from all angles
            </h3>
            <h6 className="text-primary-dark text-xl mb-4 md:mb-7">
              Cyber threats can come from any part of a business that uses
              digital technology.
            </h6>
            <p className="text-lg mb-4 md:mb-7">
              Mobile Device Management (MDM) enables companies to improve
              productivity by letting employees use mobile devices for work. A
              wide range of security and management capabilities ensures that
              company data is secure, for example, by configuring access to
              business information and email on personal or business-owned
              mobile devices.
            </p>
            <Link
              to="/contact-us"
            
            >
              <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm md:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
                Enquire Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecurityCoverage