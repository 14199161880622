import React from 'react'
import { IoIosArrowForward } from 'react-icons/io';

type Props = {}

const Benefits = (props: Props) => {
  return (
    <div className="py-2 md:py-16" id="linear-bg">
      <div className="container">
        <div className="flex flex-wrap -mx-6">
          <div className="w-full md:w-1/2 px-6 mb-8 md:mb-0">
            <div className="h-full p-8 sm:pt-10 sm:pb-5 lg:px-12 border-2 border-primary-light-15 rounded hover:border-primary100 transition-colors duration-200 hover-bg-white cursor-pointer">
              <h2 className="text-primary-dark leading-tight mb-6 text-3xl md:text-4xl font-bold">
                Benefits
              </h2>
              <ul>
                <li className="flex items-start pb-6">
                  <span className="pt-[0.3rem] pr-2 text-xl text-primary100">
                    <IoIosArrowForward />
                  </span>
                  <p className="text-primary-dark font-semibold leading-tight text-lg md:text-xl">
                    Comprehensive UEM solution
                  </p>
                </li>
                <li className="flex items-start pb-6">
                  <span className="pt-[0.3rem] pr-2 text-xl text-primary100">
                    <IoIosArrowForward />
                  </span>
                  <p className="text-primary-dark font-semibold leading-tight text-lg md:text-xl">
                    Manage iOS and Android smartphones and tablets
                  </p>
                </li>
                <li className="flex items-start pb-6">
                  <span className="pt-[0.3rem] pr-2 text-xl text-primary100">
                    <IoIosArrowForward />
                  </span>
                  <p className="text-primary-dark font-semibold leading-tight text-lg md:text-xl">
                    Sophos Container for email, documents, and web content
                  </p>
                </li>
                <li className="flex items-start pb-6">
                  <span className="pt-[0.3rem] pr-2 text-xl text-primary100">
                    <IoIosArrowForward />
                  </span>
                  <p className="text-primary-dark font-semibold leading-tight text-lg md:text-xl">
                    Award-winning protection
                  </p>
                </li>
                <li className="flex items-start pb-6">
                  <span className="pt-[0.3rem] pr-2 text-xl text-primary100">
                    <IoIosArrowForward />
                  </span>
                  <p className="text-primary-dark font-semibold leading-tight text-lg md:text-xl">
                    Manage the full device for corporate devices or just the
                    Sophos Container for BYOD devices
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-6">
            <div className="h-full bg-primary100 text-white relative rounded p-6 sm:py-10 md:pb-12 lg:px-14">
              <h3 className="leading-tight mb-6 text-3xl md:text-4xl font-bold">
                Benefits
              </h3>
              <p className="text-lg mb-6">
                We work to understand your business and provide tailored digital
                security monitoring to match your business requirements.
              </p>
              <p className="text-lg mb-6">
                Sophos Mobile enables companies to improve productivity by
                letting employees use mobile devices for work. A wide range of
                security and management capabilities ensures that company data
                is secure, for example, by configuring access to business
                information and email on personal or business-owned mobile
                devices.
              </p>
              <p className="text-lg mb-6">
                TPS offers a range of Sophos solutions and when combined and
                managed through Sophos Central enhances the security around all
                Endpoint devices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits