import React from 'react'
import { PiCaretRight } from 'react-icons/pi';


const SupportEnquiry = () => {
  return (
    <section className="py-14 bg-white">
      <div className="max-w-screen-xl px-8 mx-auto">
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 md:flex items-center mb-12 md:mb-0 ,d:pr-11 lg:pr-16 xl:pr-20">
            <div>
              <h2 className="text-primary-dark text-xl sm:text-2xl leading-tight font-bold mb-7">
                We'll have you back up and running in no time!
              </h2>
              <p className="text-primary-dark text-xl mb-7 font-bold">
                Please follow the instructions provided to you and we will help
                to resolve your IT challenge.
              </p>
              <div>
                <p className="font-normal mb-5">
                  <strong>Step 1</strong>– Follow the “Get TeamViewer” link
                  opposite.
                </p>
                <p className="font-normal mb-5">
                  <strong>Step 2</strong>– Once the download has completed, run
                  the program.
                </p>
                <p className="font-normal mb-5">
                  <strong>Step 3</strong>– When the program is running, it will
                  display a code – please pass this code to your engineer.
                </p>
                <p className="font-normal mb-5">
                  <strong>Step 4</strong>– The engineer will now remote into
                  your machine – you can watch this happen.
                </p>
                <p className="font-normal mb-5">
                  <strong>Step 5</strong>– That’s all for now.
                </p>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <div className="h-full bg-primary100 p-7 xl:p-11 rounded-md">
              <h2 className="text-white font-semibold text-2xl md:text-3xl mb-7">
                Support Enquiry
              </h2>
              <p className="text-primary-dark text-lg sm:text-xl mb-7">
                We use TeamViewer to conduct remote support, file transfers,
                desktop sharing and various other support related activities
              </p>
              <p className="text-white pb-5 lg:pb-6">
                Secured by end-to-end encryption, TeamViewer is a trusted
                application for remote support services.
              </p>
              <a
                href="/"
                className="inline-flex items-center mt-4 py-3 px-8 border-2 border-white rounded-full bg-primary100 hover:bg-[hsla(0,0%,98%,.2)] hover:border-transparent text-white"
              >
                Get TeamViewer
                <span className="ml-5 text-lg">
                  <PiCaretRight />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SupportEnquiry