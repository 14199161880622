import React from 'react'
import SupportHero from './components/SupportHero'
import './support.css'
import SupportEnquiry from './components/SupportEnquiry'
import SupportFaqs from './components/SupportFaqs'
import SupportBtn from './components/SupportBtn'

const Support = () => {
  return (
    <>
      <SupportHero />
      <SupportEnquiry />
      <SupportFaqs />
      <SupportBtn />
    </>
  );
}

export default Support