import React from 'react'
import Navbar from '../Home/components/Navbar';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className="container flex items-center justify-center my-8 md:my-16">
        <div className="text-center py-5 md:py-10">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-5 md:mb-8">
            Page not found
          </h1>
          <p className="text-xl md:text-2xl font-semibold">
            Oops! We can't seem to find the page you requested.
          </p>
          <p className="text-sm xs:text-lg md:text-xl my-2">
            This page may have moved or is no longer available. Please try one
            of the following:
          </p>
          <div className="flex items-center justify-center gap-4 my-5">
            <Link to="/">
              <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-primary100 text-black font-normal text-sm sm:text-lg hover:bg-primary100 hover:text-white hover:transition-colors hover:duration-300">
                Return Home
              </button>
            </Link>
            <Link to="/contact-us">
              <button className="px-6 py-2 md:px-8 md:py-3 rounded-full bg-transparent border-2 border-transparent hover:border-primary100 text-black font-normal text-sm sm:text-lg bg-[#f4f4f4] hover:bg-white hover:transition-colors hover:duration-300">
                Get in touch
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound