import React from "react";
import { Link } from "react-router-dom";
import TPSSvg from "../../utils-component/tpsSvg";
import { PiCaretRight } from "react-icons/pi";
import tpsText from "../../utils-component/tpsText";

const Footer = () => {
  return (
    <footer className="bg-[#191c21] text-white overflow-x-hidden">
      <div className="max-w-screen-xl mx-auto px-7">
        <div className="flex flex-col lg:flex-row py-10 border-b-2 border-primary">
          <div className="flex flex-col justify-center lg:w-1/2 lg:pl-6">
            <h2 className="text-2xl sm:text-3xl md:text-4xl leading-tight mb-5 font-semibold">
              Get in Touch
            </h2>
            <p className="text-lg md:w-3/4 lg:w-full mb-5 font-normal">
              Looking to expand or protect your IT infrastructure? Discover how
              we can make IT work smarter for your business.
            </p>
            <Link
              to="/contact-us"
              className="inline-block self-start bg-transparent border-2 rounded-full border-primary100 text-white text-center px-6 py-2 md:px-8 md:py-3 hover:bg-primary100"
            >
              Make an Enquiry
            </Link>
          </div>
          <div className="lg:w-1/2">
            <TPSSvg />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row flex-wrap py-12">
          <div className="w-full sm:w-1/2 md:w-full lg:w-1/4">
            <div className="bg-[#121418] border-2 border-primary rounded px-5 sm:px-7 py-5 sm:py-7 mb-7">
              <div className="flex flex-col">
                <h6 className="text-primary100 mb-4 text-lg">Support</h6>
                <p className="mb-6 md:mb-0 lg:mb-6">
                  Talking with an Engineer? Access remote support.
                </p>
                <Link
                  to="/support"
                  className="inline-flex w-[85%] items-center py-3 justify-center border-2 border-primary100 bg-transparent rounded-full hover:bg-primary100 text-white"
                >
                  Remote support
                  <span className="ml-5 text-lg">
                    <PiCaretRight />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-full lg:w-1/4 mb-7">
            <div className="sm:pl-12 md:pl-0 md:pr-4 lg:pl-12 lg:pr-0">
              <h6 className="text-primary100 mb-5">Services</h6>
              <ul>
                <li className="mb-3">
                  <Link to="/services/cloud">Cloud Solutions</Link>
                </li>
                <li className="mb-3">
                  <Link to="/services/cyber-security">Cyber Security</Link>
                </li>
                <li className="mb-3">
                  <Link to="/services/communications">Communications</Link>
                </li>
                <li className="mb-3">
                  <Link to="/services/it-support">IT Support</Link>
                </li>
                <li className="mb-3">
                  <Link to="/services/backup-recovery">Backup & Recovery</Link>
                </li>
              </ul>
              <div className="mt-6">
                <h6 className="text-primary100 mb-5">{tpsText()}</h6>
                <ul>
                  <li className="mb-3">
                    <Link to="/about">About</Link>
                  </li>
                  <li className="mb-3">
                    <Link to="/knowledge-base">Knowledge Base</Link>
                  </li>
                  <li className="mb-3">
                    <Link to="/success-stories">Success Stories</Link>
                  </li>
                  <li className="mb-3">
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li className="mb-3">
                    <span>Careers</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="w-full sm:w-1/2 md:w-full lg:w-1/4 mb-7">
            <div className="sm:pl-14 md:pl-0 md:pr-4 lg:pl-12 lg:pr-0">
              <h6 className="text-primary100 mb-5">Get in touch</h6>
              <ul>
                <li className="mb-3">
                  <a href="mailto:support@thepointservices.com">
                    support@thepointservices.com
                  </a>
                </li>
              </ul>
              <div className="mt-6">
                <h6 className="text-primary100 mb-5">Nigeria</h6>
                <ul>
                  <li className="mb-3">
                    <a href="tel:+234(0)8073782015">+234(0)8073782015</a>
                  </li>
                  <li className="mb-3">9, Tokumbo Ali, Toyin Street</li>
                  <li className="mb-3">Ikeja, Lagos.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full sm:w-1/2 md:w-full lg:w-1/4 mb-7">
            <div className="sm:pl-14 md:pl-0 md:pr-4 lg:pl-14 lg:pr-0">
              <h6 className="text-primary100 mb-5">Stay up to date</h6>
              <p className="mb-3">
                Discover the latest industry news, offers & more!
              </p>
              <div className="max-w-full my-4">
                <form className="flex items-center gap-4">
                  <input
                    placeholder="Email Address"
                    type="email"
                    value=""
                    name="email"
                    id="email"
                    required
                    className="bg-white border-[3px] border-primary100 outline-primary100 rounded-full pl-4 pr-8 h-10 w-48"
                  />
                  <button
                    type="submit"
                    className="inline-flex border-2 border-primary100 rounded-full p-[0.65rem]"
                  >
                    <PiCaretRight className="text-lg" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 md:gap-0 justify-between md:flex-row max-w-screen-xl mx-auto py-4">
          <div className="text-gray-500">
            <h4 className="text-sm leading-5 font-bold">
              © Copyright {new Date().getFullYear()} TPS
            </h4>
            <p className="text-xs leading-5 max-w-xl mx-auto">
              This site is protected by reCAPTCHA
            </p>
            <p className="text-xs leading-5 max-w-xl mx-auto">
              <a href="https://policies.google.com/privacy">
                The Google Privacy Policy and Terms of Service apply.
              </a>
            </p>
          </div>
          <div className="text-gray-500 justify-between">
            <div className="flex flex-col md:flex-row gap-3">
              <Link to={"/privacy"}>
                <p className="hover:text-white duration-200 cursor-pointer">
                  Privacy Policy
                </p>
              </Link>
              <p>cookie Policy</p>
              <Link to={"/terms"}>
                <p className="hover:text-white duration-200 cursor-pointer">
                  Terms and Conditions
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
