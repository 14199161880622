import React from "react";
import Navbar from "../../Home/components/Navbar";
import TPSSvg from "../../utils-component/tpsSvg";
import hero from "../../../assets/images/typing.jpg"

const Hero = () => {
  return (
    <section
      id="hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      <div className="bg-black py-14 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left px-6 md:px-12 md:py-8 lg:px-14 lg:py-10">
            <p className="text-primary100 font-semibold text-lg">Our Work</p>
            <h1 className="text-white text-4xl md:text-5xl font-semibold md:font-bold text-left my-6 md:my-8 leading-[3.5rem]">
              Success Stories
            </h1>
            <p className="text-white text-semibold text-left mb-10 text-xl">
              Discover how TPS has helped clients to overcome IT challenges &
              improve business operations.
            </p>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:w-1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
