import React from 'react'
import control from "../../../../../assets/images/control.jpg"

type Props = {}

const MobileControl = (props: Props) => {
  return (
    <div className="py-5 md:py-14">
      <div className="container">
        <div className="relative flex flex-col md:flex-row items-center md:items-stretch">
          <div className="w-full md:w-1/2 mt-4 md:mt-0 md:pl-5 md:order-2">
            <h3 className="text-primary-dark text-2xl md:text-4xl leading-tight font-bold mb-7">
              Control and protection without loss of privacy
            </h3>
            <h6 className="text-primary-dark text-xl mb-7">
              Keeping business data protected and secure on personal or
              company-owned mobile devices is critical.
            </h6>
            <p className="text-lg mb-7">
              To make sure that business data is safe and personal information
              is private, Sophos Mobile comes with a container for email and
              documents that can be deployed either with or without management
              of the device itself. Container-only management gives admins
              control over corporate content without intruding on the users’
              privacy, ideal in BYOD scenarios.
            </p>
          </div>
          <div className="w-full md:w-1/2 md:flex md:flex-col md:justify-center md:pr-10 lg:pr-20 order-1">
            <div className="w-full h-full md:aspect-ratio-square relative">
              <img src={control} alt="" className='w-full h-auto rounded shadow-lg md:h-full md:absolute md:inset-0 object-cover'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileControl