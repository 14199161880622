import React from "react";
import Navbar from "../../Home/components/Navbar";
import TPSSvg from "../../utils-component/tpsSvg";
import hero from "../../../assets/images/knowledge-hero.jpg"

export default function FaqsHero() {
  return (
    // HERO WRAPPER
    <section
      id="faqs-hero"
      className="bg-black bg-opacity-70"
      style={{ backgroundImage: `url(${hero})` }}
    >
      <Navbar />
      {/* CONTENT WRAPPER */}
      <div className="bg-black py-10 md:pb-14 bg-opacity-70 max-h-screen overflow-hidden ">
        <div className="flex md:flex-row">
          {/* lrft text */}
          <div className="text-left px-6 md:px-16 md:py-12">
            <text className="text-left text-blue-400">Knowledge Base</text>
            <div className="text-left my-8 font-bold text-3xl md:text-5xl text-white md:leading-tight">
              Frequently Asked Questions
            </div>
            <text className="text-white text-left text-xl">
              We'er always happy to help!
            </text>
          </div>

          {/* Svg */}
          <div className="hidden relative md:block md:w-1/2 md:2/3 lg:1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}
