import React from 'react'
import Navbar from '../../../../Home/components/Navbar'
import TPSSvg from "../../../../utils-component/tpsSvg";
import hero from "../../../../../assets/images/contact-hero.jpg"

type Props = {}

const MobileHero = (props: Props) => {
  return (
    <section id="mobile-hero" style={{ backgroundImage: `url(${hero})` }}>
      <Navbar />
      <div className="bg-black py-14 md:py-10 bg-opacity-70 max-h-screen overflow-hidden">
        <div className="flex md:flex-row">
          <div className="text-left px-6 md:p-12 md:pt-8 lg:p-14 lg:py-8">
            <p className="text-primary100 font-semibold text-lg">
              Cyber Security
            </p>
            <h1 className="text-white text-4xl md:text-5xl font-semibold md:font-bold text-left my-6 md:leading-tight">
              Mobile Device Management
            </h1>
            <p className="text-white text-semibold text-left mb-10 text-xl">
              Monitoring and Identifying Cyber Threats for Businesses.
            </p>
          </div>
          {/* svg/logo */}
          <div className="hidden md:block w-full md:w-1/2">
            <TPSSvg />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MobileHero